@import "../../../styles/vars";
@import "../../../styles/mixins";

.secondaryBtn {
  cursor: pointer;
  border: none;
  background-color: $color-main;
  border-radius: 52px;
  @include setFont(16px, 400);
  padding: 10px 16px;

  &:disabled {
    cursor: default;
  }

  @include smScreenMedia {
    font-size: 15px;
  }
}