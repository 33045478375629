@import "../../../styles/vars";
@import "../../../styles/mixins";

.adminEmployeesList {

  &__addEmployeeBtn {
    margin-top: 20px;
    margin-bottom: 30px;

    @include smScreenMedia {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  &__main {
    @include flexCol();
    flex-direction: column;
    gap: 20px;
  }


}
