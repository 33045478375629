@import "../../../../styles/vars";
@import "../../../../styles/mixins";


.filterModal {
  width: 100%;
  max-width: 440px;
  background-color: #252a4a;
  padding: 10px 20px 20px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: hidden;
  z-index: 99999999;

  @include smScreenMedia {
    max-width: calc(100% - 32px);
    height: calc(100% - 80px);
    top: 50%;
    transform: translate(calc(0% - 16px), -50%);
    padding: 20px 16px 0px;
    border-radius: 20px;
  }

  &__crossBtn {
    top: 10px;
    right: 16px;
    display: none;
    @include smScreenMedia {
      display: block;
    }
  }


  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding-left: 2px;
    padding-right: 6px;
    height: 100%;

    @include smScreenMedia {
      height: calc(100% - 30px);
      margin-top: 10px;
    }
  }

  &__item {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-border;
  }

  &__title {
    margin-bottom: 8px;
    gap: 5px;

    @include smScreenMedia {
      text-align: center;
    }

    & svg {
      @include svg(18px,$color-white,12px)
    }
  }

  &__btnsBlock {
    @include flexCol();
    gap: 5px;
    margin-top: 20px;

    @include smScreenMedia {
      margin-top: 10px;
    }
  }

}
