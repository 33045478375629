@import "../../../styles/vars";
@import "../../../styles/mixins";

.deletePopup {
  @include flexCol(center,center);
  flex-direction: column;

  &__btns {
    @include flexCol();
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
    gap: 20px;
    width: 100%;
  }

  &__deleteBtn {
    background-color: $color-red!important;
  }
}
