@import "../../../styles/vars";
@import "../../../styles/mixins";

.accordion {
  position: relative;
  z-index: 1;

  & {
    .accordion__container {
      border-bottom: 1px solid $color-white;
    }
  }

  &__container {
    color: $color-white;
  }

  &__header {
    @include flexCol(space-between);
    cursor: pointer;
  }

  &__title {
    width: 100%;
    @include setFont(20px, 500);
    margin-right: 30px;
    line-height: 30px;
    cursor: pointer;


    @include smScreenMedia {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__contentTextWrapper {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: $transition;

    &_active {
      grid-template-rows: 1fr;
    }
  }

  &__contentTextInner {
    overflow: hidden;
  }

  &__contentText {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 12px;
    flex-direction: column;

    @include lgScreenMedia {
      padding-top: 10px;
    }

    @include mdScreenMedia {
      gap: 10px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    @include smScreenMedia {
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 4px;
      gap: 7px;
    }


    ul {
      margin: 5px 0 5px 40px;

      @include lgScreenMedia {
        margin: 0 0 0 4%;
      }

      @include mdScreenMedia {
        margin: 0 0 0 5.5%;
      }

      li {
        margin: 10px 0;

        @include xlScreenMedia {
          margin: 7px 0;
        }
        @include lgScreenMedia {
          margin: 5px 0;
        }
      }
    }
  }

  .accordion__icon {
    width: 30px;
    height: 30px;
    position: relative;
    transition: $transition;

    &_active {
      transform: rotate(90deg);

      .accordion__iconItem_horizontal {
        opacity: 0;
      }
    }


    @include smScreenMedia {
      width: 18px;
      height: 28px;
    }
  }

  &__iconItem {
    width: 10px;
    height: 2px;
    background-color: $color-white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    transition: $transition;

    &_vertical {
      transform: translate(-50%, -50%) rotate(90deg);
    }

  }
}