@import "../../../../styles/vars";
@import "../../../../styles/mixins";


.paymentListItem {
  background-color: $color-blue-rhino;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: $transition;
  position: relative;

  &:hover {
    box-shadow: 0px 0px 0px 1px $color-main;
  }

  &__actions {
    position: absolute;
    right: 20px;
    top: 20px;
    @include flexCol();
    gap: 8px;
  }
  &__deleteBtn {
    border: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 8px;
    @include flexCol();
    background-color: $color-red;
  }

  &__deleteIcon {
    @include svg(26px,transparent)
  }
  &__editBtn {
    border: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 8px;
    @include flexCol();
    background-color: $color-main;
  }

  &__editIcon {
    @include svg(26px,transparent)
  }

  &__mainText {
    @include setFont(24px, 400);
    margin-bottom: 8px;
  }

  &__secText {
    @include setFont(16px, 400);
    margin-bottom: 6px;
  }

  &__itemBottomBlock {
    margin-top: auto;
  }

  &__filesBtn {
    cursor: pointer;
    background-color: $color-blue-active;
    @include setFont(16px, 400);
    border: none;
    width: 100%;
    padding: 20px 6px;
    border-radius: 30px;
    @include flexCol();
    margin: 30px auto 40px;
  }

  &__itemStatusBtns {
    @include flexCol(space-between, flex-start)
  }

  &__itemStatusBtnBlock {
    @include flexCol();
    flex-direction: column;
    gap: 6px;
  }

  &__itemStatusBtnDateText {
    @include setFont(14px, 400)
  }
}

