@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.adminEmployeesListItem {
  background-color: $color-blue-rhino;
  border-radius: 10px;
  padding: 14px 30px;
  width: 100%;

  @include lgScreenMedia {
    padding-left: 20px;
    padding-right: 20px;
  }


  &__header {
    @include flexCol(space-between);
    border-bottom: 1px solid $color-border;
    padding-bottom: 6px;
    gap: 14px;

    @include lgScreenMedia {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__ammountBlock {
    @include flexCol();
    gap: 20px;

    @include lgScreenMedia {
      gap: 10px;
      justify-content: flex-start;
    }

    @include mdScreenMedia {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__amountText {
    text-align: center;
    margin: 0 auto;
  }

  &__itemBtn {
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: $color-main;
    border-radius: 6px;
    padding: 6px 8px;
    @include setFont(14px, 700)
  }

  &__hideBtn {
      background-color: $color-polo-blue;
  }

  &__deleteBtn {
    background-color: $color-red;
  }

  &__cardsBlockHeader {
    @include flexCol(space-between);
    flex-direction: column;
    gap: 6px;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  &__cardsTitle {
    @include setFont(14px, 600, $color-polo-blue);
  }

  &__addCardBtn {
    cursor: pointer;
    border: none;
    border-radius: 6px;
    background-color: $color-main;
    padding: 8px;
    @include flexCol();
    gap: 6px;
    white-space: nowrap;
  }

  &__plusIcon {
    @include svg(16px, $color-white, 16px, $color-white)
  }

  &__addCardBtnText {
    @include setFont(14px, 700)
  }

  &__cardsList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__cardItem {
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid $color-white;
    padding: 12px 30px;
    background-color: $color-blue-bold;
    @include flexCol(space-between);
    gap: 10px;
    transition: $transition;

    &:hover {
      background-color: $color-blue-bg;
      border-color: $color-main;
    }

    @include lgScreenMedia {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mdScreenMedia {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
    }
  }

  &__cardAmountBlock {
    @include flexCol();
    gap: 30px;

    @include lgScreenMedia {
      gap: 10px;
    }

    @include mdScreenMedia {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
