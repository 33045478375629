@import "../../../styles/vars";
@import "../../../styles/mixins";


.yearsListWrapper {
  @include flexCol(flex-start, stretch);
  gap: 14px;
  position: relative;

  @include smScreenMedia {
    flex-direction: column-reverse;
  }
}