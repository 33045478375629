@import "../../../styles/vars";
@import "../../../styles/mixins";


.paymentList {
  padding-bottom: 40px;

  &__subtitle {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__functions {
    @include flexCol(space-between);
    margin-bottom: 20px;
    gap: 20px;
  }

  &__deleteBtn {
    background-color: $color-red;
  }

  &__wrapper {
    @include flexCol(flex-start, stretch);
    gap: 14px;
    position: relative;

    @include smScreenMedia {
      flex-direction: column-reverse;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    align-items: stretch;
    padding: 1px;
    padding-bottom: 20px;

    @include xlScreenMedia {
      grid-template-columns: repeat(2,1fr);
    }

    @include lgScreenMedia {
      grid-template-columns: 1fr;
    }
  }
}
